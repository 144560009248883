import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import './Header.scss';

function Header() {
  const [openNav, setopenNav] = useState(false);
  const [headerTop, setheaderTop] = useState(true);

  const navOpenTrigger = () => {
    setopenNav((state) => !state);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset <= 0) {
        setheaderTop(true);
      } else {
        setheaderTop(false);
      }
    });
  }, []);
  return (
    <header>
      <div className={`header-main py-3 ${headerTop ? 'top' : ''}`}>
        <div className="container">
          <div className="flex items-center">
            <div className="w-1/4">
              <Link to="/">
                <img
                  src="/img/effipac-logo.svg"
                  className="max-w-[120px]"
                  alt=""
                />
              </Link>
            </div>

            <div className="w-[75%]">
              <div className="nav-main">
                <div
                  className={`hamburger-menu xl:hidden ${
                    openNav ? 'open' : ''
                  }`}>
                  <button
                    onClick={navOpenTrigger}
                    className="hamburger button-unstyled"
                    type="button">
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>
                <ul
                  className={`nav-block flex gap-3 justify-end items-center ${
                    openNav ? 'open' : ''
                  }`}>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/collaboration">Collaboration</Link>
                  </li>
                  <li>
                    <Link to="/features">Features &amp; benefits</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/#faq">FAQs</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
