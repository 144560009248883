import React from 'react';

function Footer() {
  return (
    <footer>
      <div className="footer-main bg-lightgreen section-gap">
        <div className="container">
          <div className="row">
            <div className="col col-3">
              <a href="/">
                <img src="/img/effipac-logo.svg" className="w-[150px]" alt="" />
              </a>

              <div className="footer-links pt-5 mb-4">
                <div className="mb-1">
                  <a
                    href="#!"
                    className="hover:text-black text-primary text-14">
                    Home
                  </a>
                </div>
                <div className="mb-1">
                  <a
                    href="#!"
                    className="hover:text-black text-primary text-14">
                    Features &amp; benifits
                  </a>
                </div>
                <div className="mb-1">
                  <a
                    href="#!"
                    className="hover:text-black text-primary text-14">
                    Privacy policy
                  </a>
                </div>
                <div className="mb-1">
                  <a
                    href="#!"
                    className="hover:text-black text-primary text-14">
                    Terms &amp; conditions
                  </a>
                </div>
              </div>
            </div>

            <div className="col col-3">
              <h5 className="footer-head text-primary text-18 md:text-24 font-medium mb-3 md:mb-5">
                Contact Us
              </h5>

              <div className="footer-links mb-4">
                <div className="mb-2">
                  <h4 className="text-14 md:text-18 font-semibold text-primary">
                    For any general inquiries &amp; support
                  </h4>
                </div>
                <div className="mb-1">
                  <p className="mb-0 text-primary text-14">
                    Email us:&nbsp;
                    <a
                      href="mailto:info.effipac@gmail.com"
                      className="hover:text-black text-primary">
                      info.effipac@gmail.com
                    </a>
                  </p>
                </div>
                <div className="mb-1">
                  <p className="mb-0 text-primary text-14">
                    Call us:&nbsp;
                    <a
                      href="tel:88 0909 4343"
                      className="hover:text-black text-primary">
                      88 0909 4343
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col col-2">
              <h5 className="footer-head text-primary text-18 md:text-24 font-medium mb-3 md:mb-5">
                Socials
              </h5>

              <div className="footer-links mb-4">
                <div className="mb-1">
                  <a
                    href="https://www.facebook.com/profile.php?id=100083081061104"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-black text-primary">
                    Facebook
                  </a>
                </div>
                <div className="mb-1">
                  <a
                    href="https://www.instagram.com/effipac/"
                    target="_blank"
                    className="hover:text-black text-primary">
                    Instagram
                  </a>
                </div>
                <div className="mb-1">
                  <a
                    href="https://www.linkedin.com/company/effipac/"
                    rel="noopener noreferrer"
                    className="hover:text-black text-primary">
                    Linkedin
                  </a>
                </div>
              </div>
            </div>
            <div className="col col-4">
              <h5 className="footer-head text-primary text-18 md:text-24 font-medium mb-3 md:mb-5">
                Designed &amp; Developed By
              </h5>

              <a
                href="http://www.harpa.co.in/"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src="/img/harpa-logo.png"
                  className="max-w-[40px] md:max-w-[60px]"
                  alt=""
                />
              </a>
            </div>
          </div>

          <p className="text-14 pt-5 text-primary">
            &copy; All copyrights are reserved
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
